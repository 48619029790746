// components/VirtualAvatar.tsx
import React, {useEffect, useRef, useState} from 'react';
import {useDraggable} from './hooks/useDraggable';
import {createEventHandlers} from './utils/eventHandlers';
import SpeechBubble from "./SpeechBubble";
import {loadLive2DModel} from "./components/LoadLive2DModel"
import {useVisibility} from "./contexts/AvatarContext";
import {screenCaptureManager} from "./ScreenCaptureManager";
import DonationButtons from "./DonationButtons";
import io from "socket.io-client";
import {API_URL} from "../apis/http";

interface VirtualAvatarProps {
    scale?: number;
    address?: string;
    initX?: number;
    initY?: number;
}

const VirtualAvatar: React.FC<VirtualAvatarProps> = ({
                                                         scale = 1,
                                                         address,
                                                         initX = 100,
                                                         initY = 10
                                                     }) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const {state, position, updatePosition, startInertia} = useDraggable(initX, initY, scale);
    const {isVisible, currentModelPath} = useVisibility();
    const socketRef = useRef<any>(null);

    useEffect(() => {
        socketRef.current = io(API_URL);

        socketRef.current.on('connect', () => {
            console.log('Socket connected');
        });

        return () => {
            console.log('Socket disconnected');
            socketRef.current?.disconnect();
        };
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) {
            console.log('Canvas not found');
            return;
        }

        // @ts-ignore
        loadLive2DModel("live2d", currentModelPath)
            .then(() => {
                console.log('Live2D model loaded');
            })
            .catch((error) => {
                console.error('Failed to load Live2D model:', error);
            });

        const handlers = createEventHandlers(
            canvas,
            state,
            updatePosition,
            startInertia,
        );

        // Add event listeners
        canvas.addEventListener('mousedown', handlers.handleMouseDown);
        window.addEventListener('mousemove', handlers.handleMouseMove);
        window.addEventListener('mouseup', handlers.handleMouseUp);
        canvas.addEventListener('touchstart', handlers.handleTouchStart);
        window.addEventListener('touchmove', handlers.handleTouchMove);
        window.addEventListener('touchend', handlers.handleTouchEnd);

        // Initialize position
        updatePosition(canvas, position.x, position.y);
        screenCaptureManager.enable();

        return () => {
            cancelAnimationFrame(state.animationFrameId);
            // Remove event listeners
            canvas.removeEventListener('mousedown', handlers.handleMouseDown);
            window.removeEventListener('mousemove', handlers.handleMouseMove);
            window.removeEventListener('mouseup', handlers.handleMouseUp);
            canvas.removeEventListener('touchstart', handlers.handleTouchStart);
            window.removeEventListener('touchmove', handlers.handleTouchMove);
            window.removeEventListener('touchend', handlers.handleTouchEnd);
        };
    }, [scale, isVisible, currentModelPath]);

    if (!isVisible) {
        screenCaptureManager.disable();
        return null;
    }

    return (
        <div style={{
            zIndex: 1000,
            position: 'relative',
            overflow: 'visible',
            width: 'auto',
        }}>
            <div style={{
                position: 'absolute',
                left: '50%',
                bottom: '100%',
                transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
                pointerEvents: 'none',
            }}>

                <SpeechBubble
                    // @ts-ignore
                    style={{
                        marginBottom: '10px'
                    }}
                />
            </div>
            <canvas
                id="live2d"
                ref={canvasRef}
                width={400}
                height={600}
                style={{
                    position: 'absolute',
                    willChange: 'transform',
                    transformOrigin: '0 0',
                    touchAction: 'none',
                    userSelect: 'none',
                    cursor: 'move',
                }}
            />
            {
                address &&
                <div style={{
                    position: 'absolute',
                    right: `${60 / scale}px`,
                    top: `${scale * 600}px`,
                    transform: `translate3d(${position.x}px, ${position.y}px, 0)`,
                    pointerEvents: 'auto',
                }}>
                    <DonationButtons address={address}/>
                </div>
            }
        </div>

    );
};

export default VirtualAvatar;
