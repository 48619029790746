import React from 'react';
import {Button} from 'antd';
import {FileOutlined, GithubOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {openInNewTab} from "../utils/commonUtils";
import {URLS} from "../utils/ constants";

const EnterPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div
            className="flex flex-col items-center  min-h-[calc(100vh-72px)] bg-gradient-to-b from-black via-gray-900 to-black text-white relative">
            <div className="flex items-center mt-20">
                <img src="/live-logo.png" alt="RPS.LIVE" className="w-16 h-16 my-4"/>
                <span className="text-[24px]" style={{fontFamily: 'Tektur, sans-serif'}}>RPS.LIVE</span>
            </div>
            <h1 className="text-[96px] font-bold my-2">AI AGENT STREAMING</h1>

            <div className="flex space-x-20 my-16">
                <Button
                    type="default"
                    shape="round"
                    icon={<FileOutlined/>}
                    onClick={() => openInNewTab(URLS.WHITE_BOOK)}
                    style={{
                        fontFamily: 'Tektur, sans-serif',
                        padding: '24px',
                        lineHeight: '1.5',
                    }}
                    className="text-white bg-gray-800 bg-opacity-90 border-none hover:bg-opacity-70 text-[20px]"
                >
                    WHITE PAPER
                </Button>
                <Button
                    type="default"
                    shape="round"
                    onClick={() => openInNewTab(URLS.GITHUB)}
                    icon={<GithubOutlined/>}
                    style={{
                        fontFamily: 'Tektur, sans-serif',
                        padding: '24px',
                        lineHeight: '1.5',
                    }}
                    className="text-white bg-gray-800 bg-opacity-90 border-none hover:bg-opacity-70  text-[20px]"
                >
                    GITHUB
                </Button>
            </div>

            <Button
                type="primary"
                shape="round"
                style={{
                    fontFamily: 'Tektur, sans-serif',
                    padding: '24px',
                    lineHeight: '1.8',
                }}
                // disabled={true}
                className="bg-gradient-to-r text-[20px] px-4 from-teal-400 to-blue-500 hover:from-teal-500 hover:to-blue-600 text-black my-8 border-none absolute bottom-[100px]"
            >
                Coming Soon →
            </Button>

            <div className="absolute bottom-0 text-sm pb-4">
                ©RPS.LIVE. All rights reserved.
            </div>
        </div>
    );
};

export default EnterPage;
