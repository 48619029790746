import React, {useState} from "react";
import AgentMain from "../components/AgentMain";
import AgentDrawer from "../components/AgentDrawer";

const AgentPage = () => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <div className="flex min-h-screen bg-gray-900 text-white">
            {/* 主内容区域 */}
            <div
                className={`flex-1 p-6 transition-all duration-300 mr-0`}>
                <AgentMain/>
            </div>

            {/* 抽屉 */}
            <div
                className={`h-full bg-gray-800 shadow-lg transition-transform duration-300 ${isOpen ? "w-80" : "w-0"
                } overflow-hidden`}>
                {/* 抽屉内容 */}
                <AgentDrawer isOpen={isOpen}/>
            </div>

            {/* 抽屉控制按钮 */}
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="absolute bottom-4 right-2 px-2 py-2 bg-gray-700
                text-white rounded hover:bg-gray-600 z-50"
            >
                {isOpen ? "Close" : "Open"}
            </button>
        </div>
    );
};

export default AgentPage;
