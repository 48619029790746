// SpeechBubble.tsx
import React, {forwardRef, useEffect, useRef, useState} from 'react';
import {useVisibility} from "./contexts/AvatarContext";
import {db} from "../config/firebaseConfig";
import {collection, onSnapshot, query, Timestamp, where} from 'firebase/firestore';

// Global controller for speech bubble
interface SpeechController {
    show: (text: string, audioDataBase64?: string) => void;
    clear: () => void;
}

let globalSpeechControl: SpeechController | null = null;

interface SpeechBubbleProps {
    style?: React.CSSProperties;
}

const SpeechBubble = forwardRef<unknown, SpeechBubbleProps>(({style}, ref) => {
    const isSpeakingRef = useRef(false);
    const speechQueue = useRef<{ text: string, audioDataBase64?: string }[]>([]);
    const [visible, setVisible] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const [lastLoadedTimestamp, setLastLoadedTimestamp] = useState(Timestamp.fromDate(new Date()));
    const {isMute} = useVisibility();
    let voices = window.speechSynthesis.getVoices();
    const roomId = 'claw';

    useEffect(() => {
        if (!roomId || !lastLoadedTimestamp) {
            console.error('roomId or lastLoadedTimestamp is not defined');
            return;
        }

        const currentDate = new Date().toISOString().split('T')[0];
        const collectionPath = `rps-agent/${roomId}/broadcast-${currentDate}`;

        const lastTimestampRef = {current: lastLoadedTimestamp};

        const q = query(collection(db, collectionPath),
            where('timestamp', '>', lastTimestampRef.current));

        const unsubscribe = onSnapshot(q, snapshot => {
            snapshot.forEach(doc => {
                const message = doc.data().message;
                const audioDataBase64 = doc.data().audio_data;
                const timestamp = doc.data().timestamp;
                if (timestamp > lastTimestampRef.current) {
                    lastTimestampRef.current = timestamp;
                    setLastLoadedTimestamp(timestamp);
                    showSpeech(message, audioDataBase64);
                }
            });
        }, error => {
            console.error('Error listening to broadcasts:', error);
        });

        return () => unsubscribe();
    }, [lastLoadedTimestamp]);

    useEffect(() => {
        try {
            window.speechSynthesis.cancel();
            const getVoices = () => {
                if (window.speechSynthesis.getVoices().length > 0) {
                    voices = window.speechSynthesis.getVoices()
                }
            };
            window.speechSynthesis.onvoiceschanged = getVoices;
            getVoices();
            const utterance = new SpeechSynthesisUtterance('');
            window.speechSynthesis.speak(utterance);
        } catch (error) {
            console.log('Speech synthesis initialization failed:', error);
        }
    }, []);

    const speak = (text: string, isMute: boolean, audioDataBase64?: string): void => {
        if (audioDataBase64) {
            // 使用Base64音频数据
            const audioData = atob(audioDataBase64);
            const audioArray = new Uint8Array(audioData.length);
            for (let i = 0; i < audioData.length; i++) {
                audioArray[i] = audioData.charCodeAt(i);
            }
            const audioBlob = new Blob([audioArray], {type: 'audio/mpeg'});
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);

            audio.onended = () => {
                isSpeakingRef.current = false;
                processQueue();
            };

            audio.onerror = (event) => {
                console.error('Error playing audio:', event);
                isSpeakingRef.current = false;
                processQueue();
            };

            setVisible(true);
            isSpeakingRef.current = true;

            if (!isMute) {
                audio.play().catch(error => {
                    console.error('Audio play failed:', error);
                });
            }

            setTimeout(() => {
                setVisible(false);
                isSpeakingRef.current = false;
                processQueue();
            }, 3000);
        } else {
            const utterance = new SpeechSynthesisUtterance(text);
            utterance.lang = 'en-EN';
            utterance.pitch = 1.0;
            utterance.rate = 1.0;
            utterance.volume = 1.0;
            utterance.voice = voices.find(voice => voice.name === 'Samantha') || null;

            utterance.onend = () => {
                isSpeakingRef.current = false;
                processQueue();
            };

            utterance.onerror = (event) => {
                isSpeakingRef.current = false;
                processQueue();
            };

            setVisible(true);
            isSpeakingRef.current = true;

            if (window.speechSynthesis.paused) {
                window.speechSynthesis.resume();
            }
            if (!isMute) {
                window.speechSynthesis.speak(utterance);
            }

            setTimeout(() => {
                setVisible(false);
                isSpeakingRef.current = false;
                processQueue();
            }, 3000);
        }
    };

    const processQueue = () => {
        if (speechQueue.current.length > 0 && !isSpeakingRef.current) {
            // @ts-ignore
            const {text, audioDataBase64} = speechQueue.current.shift();
            if (text) {
                setCurrentText(text);
                speak(text, isMute, audioDataBase64);
            }
        }
    };

    const addToQueue = (text: string, audioDataBase64?: string): void => {
        speechQueue.current.push({text, audioDataBase64});
        if (!isSpeakingRef.current) {
            processQueue();
        }
    };

    const clearQueue = (): void => {
        speechQueue.current = [];
    };

    useEffect(() => {
        globalSpeechControl = {
            show: addToQueue,
            clear: clearQueue
        };

        return () => {
            window.speechSynthesis.cancel();
            speechQueue.current = [];
            isSpeakingRef.current = false;
            globalSpeechControl = null;
        };
    }, []);

    if (!visible) return null;

    return (
        <div style={{
            position: 'absolute',
            backgroundColor: 'white',
            padding: '10px 15px',
            borderRadius: '20px',
            boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
            transform: 'translateX(-50%)',
            minWidth: '200px',
            color: "black",
            ...style
        }}>
            {currentText}
        </div>
    );
});

// Export global method
export const showSpeech = (text: string, audioDataBase64?: string): void => {
    console.debug('Showing speech:', text);
    globalSpeechControl?.show(text, audioDataBase64);
};

export const clearSpeechQueue = (): void => {
    console.debug('Clearing speech queue');
    globalSpeechControl?.clear();
};

export default SpeechBubble;
