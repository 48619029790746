import {Agent, getAgents} from "../apis/game";
import React, {useEffect, useState} from "react";
import {Button} from "antd";

const AgentDrawer = ({isOpen}) => {

    const [agents, setAgents] = useState<Agent[]>([]);

    useEffect(() => {
        const fetchAgents = async () => {
            try {
                const response = await getAgents();
                console.log('Fetched agents:', response.data);
                const agentsWithUI = response.data.map((agent, index) => ({
                    ...agent,
                    id: index + 1,
                    avatar: ['🐧', '🤖', '🌙', '🎮', '⭐', '🌪️'][index], // Default avatars
                    position: `${index + 1}${['st', 'nd', 'rd'][index] || 'th'}`
                }));
                setAgents(agentsWithUI);
            } catch (error) {
                console.error('Failed to fetch agents:', error);
            }
        };

        fetchAgents();
    }, []);

    const formatTimestamp = (timestamp: string) => {
        // Parse timestamp string into Date object
        const date = new Date(timestamp);
        // Format as YYYY-MM-DD HH:mm:ss
        return date.toISOString().slice(0, 19).replace('T', ' ');
    };

    return (
        <div className={`p-4 ${isOpen ? "block" : "hidden"}`}>
            <h2 className="text-lg font-medium mb-4">Agents</h2>
            <div className="space-y-4">
                {agents.map((agent) => (
                    <div
                        key={agent.name}
                        className="flex items-center justify-between bg-gray-700 p-3 rounded-md"
                    >
                        <div className="flex items-center gap-3">
                            <img
                                src={agent.profile_url}
                                alt={agent.name}
                                className="w-10 h-10 rounded-full"
                            />
                            <div>
                                <h4 className="text-sm font-medium">{agent.name}</h4>
                                <p className="text-xs text-gray-400">{agent.role}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex justify-center">
                <Button
                    type="primary"
                    style={{
                        fontFamily: 'Tektur, sans-serif',
                        padding: '24px',
                        lineHeight: '1.8',
                    }}
                    // disabled={true}
                    className="bg-gradient-to-r text-[20px] from-[#3F66DD] to-[#E493D5] hover:from-teal-500 hover:to-blue-600 text-black mt-16 border-none"
                >
                    Go To Buy
                </Button>
            </div>
        </div>
    )
}
export default AgentDrawer;
