import React from 'react';
import Header from "../components/Header";
import {BrowserRouter as Router, Outlet, Route, Routes} from 'react-router-dom';
import EnterPage from "./EnterPage";
import RoomPage from "./RoomPage";
import {VisibilityProvider} from "../agi-live/contexts/AvatarContext";
import AgentPage from './AgentPage';
import Sidebar from "../components/Sidebar";

const IndexPage = () => {
    return (
        <Router>
            <div className="min-h-screen bg-[#111827]">
                {/* 顶部导航栏 */}
                <div className="bg-white shadow-md border-b border-[#5F5F5F] fixed top-0 left-0 w-full z-50">
                    <Header/>
                </div>

                {/* 主内容路由 */}
                <div className="pt-16">
                    <Routes>
                        {/* 首页 */}
                        <Route path="/" element={<EnterPage/>}/>

                        {/* 房间页面 */}
                        <Route path="/room" element={<VisibilityProvider><RoomPage/></VisibilityProvider>}/>

                        {/* 嵌套路由：包含左侧 Sidebar */}
                        <Route path="/agents" element={<AgentsLayout/>}>
                            <Route index element={<AgentPage/>}/>
                            <Route path="history" element={<div>History Content</div>}/>
                            <Route path="my-bet" element={<div>My Bet Content</div>}/>
                            <Route path="agents" element={<div>My Agents</div>}/>
                        </Route>
                    </Routes>
                </div>
            </div>
        </Router>
    );
}
const AgentsLayout = () => {
    return (
        <div className="flex h-screen ">
            {/* 左侧侧边栏 */}
            <div className="w-60 bg-gray-800 text-white" style={{height: "calc(100vh - 4rem)"}}>
                <Sidebar/>
            </div>

            {/* 右侧主内容区域 */}
            <div className="flex-1 bg-gray-900 text-white p-6 overflow-y-auto">
                <Outlet/>
            </div>
        </div>
    );
};


export default IndexPage;
