import React, {useEffect} from 'react';
import VirtualAvatar from "../agi-live/VirtualAvatar";
import {showSpeech} from "../agi-live/SpeechBubble";
import {builtInVoice} from "../utils/ constants";

const LiveBox: React.FC = () => {

    useEffect(() => {
        showSpeech(builtInVoice.Greeting.text, builtInVoice.Greeting.audio_data);
    }, []);

    return (
        <div className="flex flex-col h-[584px] items-center justify-center bg-gray-800 rounded-b-lg p-4"
             style={{backgroundImage: 'url(/assets/main-bg.png)'}}>
            <VirtualAvatar initY={-220} scale={0.8} initX={-140}/>
        </div>
    );
};

export default LiveBox;
