import axios, {AxiosInstance, AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import {message} from 'antd';


type EnvType = 'dev' | 'prod';
const ENV: EnvType = (process.env.REACT_APP_RPS_ENV as EnvType) || 'dev';

export const API_URL = {
    dev: 'http://localhost:8080',
    prod: 'https://rps-agent-backend-954067898723.us-central1.run.app'
}[ENV];


// 定义响应数据接口
interface ApiResponse<T = any> {
    code: number;
    msg: string;
    data: T;
}

// 创建axios实例
const httpInstance: AxiosInstance = axios.create({
    baseURL: '',
    timeout: 20000
});

// axios请求拦截器
httpInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        console.log('process.env.REACT_APP_RPS_ENV', process.env.REACT_APP_RPS_ENV)
        config.url = API_URL + config.url;
        // const {user} = useUser();
        // if (config.headers && user?.rpsToken) {
        //     config.headers['rps-token'] = user.rpsToken;
        // }
        return config;
    },
    (error: any) => Promise.reject(error)
);

// axios响应式拦截器
httpInstance.interceptors.response.use(
    (response: AxiosResponse) => {
        const {data, headers} = response;

        // 处理鉴权相关
        if (data.code === 1024) {
            // 先把本地登录态移除
            // useUser().logout();
            // todo
            return Promise.reject('error');
        }

        // 统一把非0的抛出来
        if (data.code !== 0) {
            message.error(data.msg)
            return Promise.reject('error');
        }

        return {
            ...data,
            headers
        };
    },
    (error: any) => {
        // 统一错误提示
        const msg = error.response?.data?.msg;
        message.error(msg || 'Network timeout, please try again later');
        return Promise.reject(error);
    }
);

// 封装请求方法
const request = {
    get: <T = any>(url: string, params?: any) => {
        return httpInstance.get<any, ApiResponse<T>>(url, {params});
    },

    post: <T = any>(url: string, data?: any) => {
        return httpInstance.post<any, ApiResponse<T>>(url, data);
    },

    put: <T = any>(url: string, data?: any) => {
        return httpInstance.put<any, ApiResponse<T>>(url, data);
    },

    delete: <T = any>(url: string, params?: any) => {
        return httpInstance.delete<any, ApiResponse<T>>(url, {params});
    }
};

export default request;
