// utils.ts

/**
 * Opens a URL in a new browser tab.
 *
 * @param url - The URL to open.
 */
export const openInNewTab = (url: string): void => {
    window.open(url, '_blank');
};
