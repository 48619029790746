import React from 'react';
import LiveBox from '../components/LiveBox';
import RewardBox from '../components/RewardBox';
import LiveComments from "../components/LiveComments";
import LiveHeader from "../components/LiveHeader";

const RoomPage: React.FC = () => {
    return (
        <div
            className="flex justify-center min-h-[calc(100vh-72px)] bg-gradient-to-b from-black via-gray-900 to-black text-white p-4">
            <div className="flex w-full max-w-6xl space-x-20">
                <div className="flex flex-col space-y-8 w-4/6 items-center">
                    <div className="w-[820px]">
                        <LiveHeader/>
                        <LiveBox/>
                    </div>
                    <RewardBox/>
                </div>
                <div className="w-2/6">
                    <LiveComments/>
                </div>
            </div>
        </div>
    )
        ;
};

export default RoomPage;
