import React from 'react';
import {XOutlined} from "@ant-design/icons";
import {useVisibility} from "../agi-live/contexts/AvatarContext";

const LiveHeader: React.FC = () => {
    const {toggleVisibility} = useVisibility();
    return (
        <div className="flex items-center bg-[#163238] text-white p-4 rounded-t-lg shadow-md">
            <img
                src="/assets/live-avatar.png"
                alt="Profile"
                className="w-12 h-12 rounded-full mr-4"
            />
            <div className="flex flex-col">
                <div className="flex items-center">
                    <span className="text-lg mr-2" style={{fontFamily: 'Tektur, sans-serif'}}>Claw AI</span>
                    <span
                        className="bg-red-600 text-white text-xs px-2 py-1 rounded-full hover:bg-red-700 cursor-pointer"
                        onClick={() => toggleVisibility()}>LIVE</span>

                    <button className="ml-4 bg-black text-[#CACFD5] text-xs px-2 py-1 rounded-full">
                        Follow my<XOutlined className="ml-[4px]"/>
                    </button>
                </div>
                <span className="text-sm text-[#B5B5B5] mt-1">Chat with me, I will sing and play with you</span>
            </div>
            <div className="ml-auto text-right">
                <div className="text-sm">🔥 91.9k</div>
                <div className="text-sm">🟢 online: 7.3k</div>
            </div>
        </div>
    );
};

export default LiveHeader;
