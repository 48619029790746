import request from "./http";

// Types
export interface GameState {
    round: number;
    status: 'waiting' | 'active' | 'finished';
    totalRounds: number;
}

export interface Agent {
    name: string;
    role: string;
    description: string;
    traits: string[];
    isEliminated: boolean;
    twitterHandle: string;
    profile_url: string;
}

export interface Conversation {
    speaker: string; 
    content: string;
    round: number;
    timestamp: string;
    type: 'message' | 'system';
}

export interface Vote {
    from: string;
    to: string;
    round: number;
}

// API calls
export const getGameState = () => {
    return request.get<GameState>('/game/state');
}

export const startGame = () => {
    return request.post('/game/start');
}

export const getAgents = () => {
    return request.get<Agent[]>('/agents');
}

export const getConversations = (round: number) => {
    return request.get<Conversation[]>('/conversations', { round });
}

export const getVotes = (round: number) => {
    return request.get<Vote[]>('/game/votes', { round });
}

export const sendMessage = (req: {
    speaker: string;
    content: string;
    round: number;
}) => {
    return request.post('/game/send-message', req);
}

export const castVote = (req: {
    from: string;
    to: string;
    round: number;
}) => {
    return request.post('/game/vote', req);
}

export const getRoundResult = (round: number) => {
    return request.get<{
        eliminated: string;
        votes: Vote[];
    }>('/game/round-result', { round });
}

export const streamLogs = () => {
    return request.get('/game/stream-logs', {
        responseType: 'stream'
    });
}

export const getLogs = () => {
    return request.get('/game/logs');
}

export const startStream = () => {
    return request.post('/game/start-stream');
}


export const saveGameLog = () => {
    return request.post('/save-game-log');
}
