import React from 'react';
import {CopyOutlined, SendOutlined, XOutlined, GithubOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
import {openInNewTab} from "../utils/commonUtils";
import {URLS} from "../utils/ constants";

const Header = () => {
    const navigate = useNavigate();
    return (
        <div className="flex items-center justify-between bg-black text-white p-4">
            <div className="flex items-center">
                <img src="/live-logo.png" alt="Logo" className="w-10 h-10 mr-2"/>
                <span className="text-[24px] hover:text-gray-400 cursor-pointer"
                      style={{fontFamily: 'Tektur, sans-serif'}}
                      onClick={() => navigate('/')}>RPS.LIVE</span>
            </div>
            <div className="flex space-x-4">
                <SendOutlined 
                    onClick={() => openInNewTab(URLS.WHITE_BOOK)} 
                    style={{fontSize: '24px'}}
                    className="hover:text-gray-400 cursor-pointer"
                    title="Docs"
                />
                <GithubOutlined 
                    onClick={() => openInNewTab(URLS.GITHUB)} 
                    style={{fontSize: '24px'}}
                    className="hover:text-gray-400 cursor-pointer"
                    title="GitHub"
                />
                <XOutlined 
                    onClick={() => openInNewTab(URLS.TWITTER)} 
                    style={{fontSize: '24px'}}
                    className="hover:text-gray-400 cursor-pointer"
                    title="Twitter"
                />
            </div>
        </div>
    );
};

export default Header;
