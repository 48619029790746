import React from "react";
import {Link, useLocation} from "react-router-dom";
import {ClipboardList, DollarSign, Trophy, Users} from "lucide-react";
import {usePrivy} from "@privy-io/react-auth";
import {Tooltip} from "antd";

const Sidebar = () => {
    const location = useLocation();
    const {login, logout, authenticated, ready, user} = usePrivy();

    // 定义菜单项
    const menuItems = [
        {name: "Race", path: "/agents", icon: <Trophy className="w-6 h-6"/>, disable: false},
        {name: "History", path: "/agents/history", icon: <ClipboardList className="w-6 h-6"/>, disable: true},
        {name: "Agents", path: "/agents/agents", icon: <Users className="w-6 h-6"/>, disable: true},
        {name: "My Bet", path: "/agents/my-bet", icon: <DollarSign className="w-6 h-6"/>, disable: true},
    ];

    // 获取链地址前 3 个字母
    const chainAddress = user?.wallet?.address || ""; // 假设 `user.wallet.address` 是链地址
    const displayAddress = chainAddress ? chainAddress.slice(0, 3) : "N/A";

    return (
        <div className="w-60 h-full bg-gray-800 text-white flex flex-col">
            {/* 顶部标题 */}
            <div
                className="text-center py-4 border-b border-gray-700 font-bold"
                style={{fontFamily: "Lato, sans-serif", fontSize: "24px"}}
            >
                Agent Race
            </div>

            {/* 菜单列表 */}
            <nav className="flex-1">
                <ul>
                    {menuItems.map((item) => (
                        <li key={item.path} className="w-full">
                            <Link
                                to={item.disable ? "#" : item.path}
                                className={`flex items-center gap-4 px-4 py-3 text-sm font-medium rounded-md ${
                                    item.disable
                                        ? "text-gray-500 cursor-not-allowed"
                                        : location.pathname === item.path
                                            ? "bg-gray-700 text-blue-400"
                                            : "text-gray-300 hover:bg-gray-700 hover:text-white"
                                }`}
                                style={{
                                    fontFamily: "Lato, sans-serif",
                                    fontSize: "20px",
                                    pointerEvents: item.disable ? "none" : "auto",
                                }}
                            >
                                {item.icon}
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
            </nav>
            {/* 登录按钮或头像显示 */}
            {ready &&
                <div className="py-4 px-4 border-t border-gray-700">
                    {authenticated ? (
                        <div className="flex items-center gap-4">
                            {/* 圆形头像 */}
                            <Tooltip title={chainAddress} placement="top">
                                <div
                                    className="w-10 h-10 bg-[#B8D9FF] text-white font-bold rounded-full flex items-center justify-center">
                                    {displayAddress}
                                </div>
                            </Tooltip>
                            {/* 登出按钮 */}
                            <button
                                onClick={logout}
                                className="ml-auto bg-red-500 hover:bg-red-600 text-white font-medium px-3 py-1 rounded-md transition duration-200"
                                style={{fontFamily: "Lato, sans-serif"}}
                            >
                                Logout
                            </button>
                        </div>
                    ) : (
                        <button
                            onClick={login}
                            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 rounded-md transition duration-200"
                            style={{fontFamily: "Lato, sans-serif", fontSize: "18px"}}
                        >
                            Login
                        </button>
                    )}
                </div>}
        </div>
    );
};

export default Sidebar;
