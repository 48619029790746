import React, {useEffect, useState} from "react";
import {Timestamp} from "firebase/firestore";

export interface SessionGame {
    created_at: string;
    game_id: string;
    start_time: Timestamp;
    game_num: number;
    status: "starting" | "not_started" | "stared";
    winning_agent?: string[];
}


const AgentLive = ({sessionGames}) => {
    // @ts-ignore
    const [currentGame, setCurrentGame] = useState<SessionGame>(null);
    const [countdown, setCountdown] = useState("");

    // Calculate countdown
    const calculateCountdown = (startTime) => {
        const now = new Date().getTime();
        const start = startTime.getTime();
        const diff = start - now;

        if (diff <= 0) {
            return "Starting soon";
        }

        // 计算小时、分钟和秒
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        return `${hours} hours ${minutes} minutes ${seconds} seconds`;
    };


    // Update countdown dynamically
    useEffect(() => {
        if (currentGame?.status === "not_started" && currentGame.start_time) {
            const timer = setInterval(() => {
                setCountdown(calculateCountdown(currentGame.start_time.toDate()));
            }, 1000);

            return () => clearInterval(timer); // Clear timer to prevent memory leaks
        }
    }, [currentGame]);

    // Monitor sessionGames data
    useEffect(() => {
        if (sessionGames && sessionGames.length > 0) {
            // Prioritize "starting" games
            const startingGame = sessionGames.find((game) => game.status === "starting");

            if (startingGame) {
                setCurrentGame(startingGame);
                return;
            }

            // If no "starting" games, find the nearest "not_started" game
            const notStartedGames = sessionGames
                .filter((game) => game.status === "not_started")
                .sort((a, b) => a.start_time.toDate() - b.start_time.toDate());

            if (notStartedGames.length > 0) {
                setCurrentGame(notStartedGames[0]); // Select the nearest not_started game
            }
        }
    }, [sessionGames]);

    return (<>
            <div
                className="absolute -top-3 -right-3 bg-emerald-500 text-xs font-mono px-2 py-1 rounded-full shadow-lg flex items-center gap-1">
                <div className="w-2 h-2 bg-emerald-300 rounded-full animate-pulse"></div>
                TEE SECURED
            </div>

            <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-3">
                    <h3 className="text-lg font-medium">Live Agent Panel</h3>
                    <div
                        className="px-2 py-1 bg-emerald-900/30 rounded-md border border-emerald-500/20 text-emerald-400 text-xs">
                        Encrypted Channel
                    </div>
                </div>
                <div className="flex items-center space-x-2">
                    <div className="h-2 w-2 bg-red-500 animate-pulse rounded-full"></div>
                    <span className="text-sm text-gray-400">LIVE</span>
                </div>
            </div>


            <div
                className="min-h-[200px] max-h-[500px] overflow-y-auto space-y-4 pr-4 custom-scrollbar backdrop-blur-sm bg-gray-800/50"
                ref={el => {
                    if (el) {
                        el.scrollTop = el.scrollHeight;
                    }
                }}>
                <div className="border-b border-emerald-500/10 pb-2 mb-2">
                    <div className="text-xs text-emerald-400/70 font-mono">
                        TEE Verification: 0xf4e2...8a91
                    </div>
                </div>
                <div className="min-h-[100px] overflow-y-auto space-y-4 pr-4 custom-scrollbar"
                     ref={el => {
                         if (el) {
                             el.scrollTop = el.scrollHeight;
                         }
                     }}>
                    <div className="text-center text-gray-300 p-4 bg-gray-800 rounded-lg">
                        {currentGame?.status === "starting" ? (
                            <div>
                                <p className="text-lg font-semibold text-emerald-400 mb-2">
                                    The game is currently in progress!
                                </p>
                                <p className="text-base text-gray-300">
                                    Watch the live broadcast on&nbsp;
                                    <a
                                        href="https://twitter.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-400 underline hover:text-blue-300"
                                    >
                                        Twitter
                                    </a>
                                    .
                                </p>
                            </div>
                        ) : currentGame?.status === "not_started" ? (
                            <div>
                                <p className="text-lg font-semibold text-gray-300 mb-2">
                                    The next round starts in:
                                </p>
                                <p className="text-2xl font-bold text-emerald-400">{countdown}</p>
                            </div>
                        ) : (
                            <p className="text-lg text-gray-400">No game information available</p>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AgentLive;
