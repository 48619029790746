// context/VisibilityContext.tsx
import React, {createContext, ReactNode, useContext, useState} from 'react';
import {MODELS} from '../utils/models';

// Assume MODELS is imported from some file

interface AvatarContextProps {
    isVisible: boolean;
    isMute: boolean;
    toggleVisibility: () => void;
    toggleMute: () => void;
    currentModelPath: string;
    changeModel: () => void;
}

const defaultContextValue: AvatarContextProps = {
    isVisible: true,
    isMute: false,
    toggleVisibility: () => {
    },
    toggleMute: () => {
    },
    currentModelPath: "model/haru/haru_01.model.json",
    changeModel: () => {
    },
};

const AvatarContext = createContext<AvatarContextProps | undefined>(undefined);

export const VisibilityProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isMute, setIsMute] = useState(false);
    const [modelIndex, setModelIndex] = useState(0);
    const [currentModelPath, setCurrentModelPath] = useState("model/haru/haru_01.model.json");
    const toggleVisibility = () => {
        console.log('toggleVisibility');
        setIsVisible((prev) => !prev)
    };
    const toggleMute = () => setIsMute((prev) => !prev);

    // Get the current model path
    const changeModel = () => {
        setModelIndex((prevModelIndex) => {
            return (prevModelIndex + 1) % MODELS.length;
        });
        setCurrentModelPath(MODELS[modelIndex][0]);
    };

    return (
        <AvatarContext.Provider
            value={{isVisible, toggleVisibility, currentModelPath, changeModel, isMute, toggleMute}}>
            {children}
        </AvatarContext.Provider>
    );
};

export const useVisibility = (): AvatarContextProps => {
    const context = useContext(AvatarContext);
    return context || defaultContextValue; // Use default value if context is undefined
};
