import React from 'react';
import {showSpeech} from "../agi-live/SpeechBubble";
import {builtInVoice} from "../utils/ constants";

const RewardBox: React.FC = () => {
    return (
        <div className="flex items-center justify-center bg-gray-800 rounded-full p-6 space-x-4"
             onClick={() => showSpeech(builtInVoice.NotAvailable.text, builtInVoice.NotAvailable.audio_data)}>
            {['🎤', '🎁', '😄', '👍', '🌹', '❤️'].map((icon, index) => (
                <div key={index} className="relative w-16 h-16">
                    <div className="absolute inset-0 bg-gradient-to-r from-[#37EEFB] to-[#2FF1B3] rounded-lg p-0.5">
                        <div
                            className="flex items-center justify-center w-full h-full bg-black bg-opacity-90 rounded-lg">
                            {icon}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RewardBox;
