import React, {useEffect, useState} from "react";
import {collection, onSnapshot} from "firebase/firestore";
import {db} from "../config/firebaseConfig";
import AgentLive from "./AgentLive";
import AgentHistory from "./AgentHistory";

const AgentMain = () => {
    const seasonNum = "0";
    const [sessionGames, setSessionGames] = useState([]);
    const [historyGames, setHistoryGames] = useState([]);

    useEffect(() => {
        const unsubscribe = listenToSessionGames(seasonNum, (updatedGames) => {
            setSessionGames(updatedGames);
            setHistoryGames(updatedGames.filter((game) => game.status === "started" || game.status === 'starting'));
        });
        return () => unsubscribe();
    }, []);

    const listenToSessionGames = (seasonNum, callback) => {
        // 引用到指定集合 ai_ctopus/{seasonNum}/session_game
        const collectionRef = collection(db, `ai_ctopus/${seasonNum}/session_game`);
        // 返回取消订阅函数
        return onSnapshot(collectionRef, (snapshot) => {
            const sessionGames = snapshot.docs.map((doc) => ({
                id: doc.id, // 获取文档 ID (game_id)
                ...doc.data(), // 获取文档数据
            }));
            console.log("Updated session_games:", sessionGames);
            // 回调返回最新的 session_games 数据
            if (callback) {
                callback(sessionGames);
            }
        });
    };


    return (<>
        <div className="mb-8 bg-gray-800 rounded-lg p-4 border-2 border-emerald-500/30 relative">
            {/* TEE Security Badge */}
            <AgentLive sessionGames={sessionGames}/>
        </div>

        {/* Vote Results Table */}
        <div className="mt-8">
            <AgentHistory seasonNum={seasonNum} historyGames={historyGames}/>
        </div>
    </>)
}
export default AgentMain;
