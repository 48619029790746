import React from 'react';
import {Connection, PublicKey, SystemProgram, Transaction} from "@solana/web3.js";
import {message} from "antd";
import {Buffer} from 'buffer';

const DonationButtons = ({amounts = [0.01, 0.02, 0.03], address = ''}) => {
    const handleDonation = async (amount) => {
        try {
            const connection = new Connection("https://api.testnet.solana.com", "confirmed");
            // @ts-ignore
            if (!window.solana.isConnected) {
                // @ts-ignore
                await window?.solana.connect();
                window.Buffer = Buffer;
                console.log('Connected to Solana');
            }

            // @ts-ignore
            const fromPublicKey = window.solana.publicKey;
            const toPublicKey = new PublicKey(address);
            const {blockhash} = await connection.getRecentBlockhash();
            const transaction = new Transaction({
                recentBlockhash: blockhash,
                feePayer: fromPublicKey,
            }).add(
                SystemProgram.transfer({
                    fromPubkey: fromPublicKey,
                    toPubkey: toPublicKey,
                    lamports: amount * 1e9,
                })
            );

            // @ts-ignore
            const signedTransaction = await window.solana.signAndSendTransaction(transaction);
            await connection.confirmTransaction(signedTransaction.signature, 'confirmed');
            console.log(`Successfully donated ${amount} SOL to ${address}`);
        } catch (error) {
            message.error("Sorry, Donation failed")
            console.error('Donation failed', error);
        }
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '10px',
        }}>
            {amounts.map((amount, index) => (
                <button
                    key={index}
                    className="bg-pink-400 hover:bg-pink-500 text-white font-bold rounded-lg shadow-lg transform transition duration-300 ease-in-out hover:scale-105 hover:shadow-2xl bounce-hover"
                    onClick={() => handleDonation(amount)}
                    style={{
                        margin: '0 10px',
                        padding: '10px 20px',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px',
                    }}
                >
                    Tipping {amount} SOL
                </button>
            ))}
        </div>
    );
};

export default DonationButtons;
