import React, {useEffect, useRef, useState} from 'react';
import {db} from '../config/firebaseConfig';
import {Input, Spin} from 'antd'; // Import Spin for loading indicator
import {collection, limit, onSnapshot, orderBy, query} from 'firebase/firestore';
import {AnimatePresence, motion} from 'framer-motion';
import {sendChat} from "../apis/chat";

export interface ChatComment {
    name: string;
    comment: string;
    timestamp: number;
}

const LiveComments = ({roomId = 'claw'}) => {
    const [comments, setComments] = useState<ChatComment[]>([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false); // State to track loading
    const commentsEndRef = useRef<HTMLDivElement>(null);

    const emojis = ['🚀', '🔥', '🤬', '😡', '😢', '💰', '📈', '📉', '👿', '😄'];

    useEffect(() => {
        const utcDate = new Date().toISOString().split('T')[0];
        const collectionPath = `rps-agent/${roomId}/chat-${utcDate}`;
        const q = query(
            collection(db, collectionPath),
            orderBy('timestamp', 'desc'),
            limit(20)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newComments: ChatComment[] = [];
            querySnapshot.forEach((doc) => {
                const data = doc.data() as ChatComment;
                newComments.push({...data, timestamp: data.timestamp});
            });
            setComments(newComments.reverse());
            scrollToBottom();
        });
        return () => unsubscribe();
    }, []);

    const scrollToBottom = () => {
        commentsEndRef.current?.scrollIntoView({behavior: "smooth", block: "end"});
    };

    const handleSend = async (emoji?: string) => {
        let commentText = emoji || input.trim();
        if (commentText === '') {
            return;
        }

        try {
            setLoading(true); // Set loading to true when starting to send
            const userAgent = navigator.userAgent;
            const browserId = userAgent.slice(-6);

            const response = await sendChat({
                name: browserId ? browserId : 'Anonymous',
                comment: commentText,
            });

            if (response.code === 0) {
                setInput('');
                scrollToBottom();
            } else {
                console.error(response.msg);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false); // Set loading to false when sending is complete
        }
    };

    const [lastCommentTimestamp, setLastCommentTimestamp] = useState(0);

    useEffect(() => {
        if (comments.length > 0) {
            setLastCommentTimestamp(comments[comments.length - 1].timestamp);
            scrollToBottom();
        }
    }, [comments]);

    return (
        <div className={`bg-gray-900 p-6 rounded-lg shadow-lg relative ${
            Date.now() - lastCommentTimestamp < 2000
                ? 'animate-border-flash'
                : ''
        }`}>
            <h2 className="text-2xl font-bold text-white mb-4 tracking-wide">Live Comments</h2>
            <div className="overflow-y-auto h-96 custom-scrollbar" style={{whiteSpace: 'pre-wrap', overflow: 'hidden'}}>
                <div style={{maxHeight: '100%', overflowY: 'auto'}}>
                    <AnimatePresence>
                        {comments.map((comment: ChatComment, index) => {
                            const nameColor = `hsl(${comment.name.charCodeAt(0) * 137.5 % 360}, 70%, 50%)`;
                            const isLatest = index === comments.length - 1;
                            const timeSinceComment = Date.now() - comment.timestamp;
                            const isNew = timeSinceComment < 2000;
                            return (
                                <motion.div
                                    key={comment.timestamp}
                                    initial={{opacity: 0, y: 20}}
                                    animate={{opacity: 1, y: 0}}
                                    exit={{opacity: 0}}
                                    transition={{duration: 0.3}}
                                    className={`text-white mb-2 p-2 rounded ${
                                        isLatest ? 'bg-gradient-to-r from-pink-500 via-purple-500 to-pink-500' : 'bg-gray-800'
                                    } ${isNew ? 'animate-quick-flash' : ''}`}
                                >
                                    <div className="flex items-center">
                                        <span
                                            className="inline-block bg-gray-800 rounded-full px-4 py-2 text-lg font-extrabold mr-3"
                                            style={{color: nameColor}}>
                                            {comment.name}
                                        </span>
                                        <p className="text-lg font-bold flex-grow break-words">
                                            {comment.comment}
                                        </p>
                                    </div>
                                </motion.div>
                            );
                        })}
                    </AnimatePresence>
                    <div ref={commentsEndRef}/>
                </div>
            </div>
            <Input
                placeholder="add comments"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onPressEnter={() => handleSend()}
                className="mt-4 bg-white text-black border-2 border-gray-300 text-lg py-2 focus:border-green-500 hover:border-green-500"
                suffix={loading ? <Spin/> : null} // Show loading spinner inside input
                disabled={loading} // Disable input while loading
            />
            <div className="grid grid-cols-5 gap-2 mt-2">
                {emojis.map((emoji, index) => (
                    <button
                        key={index}
                        onClick={() => handleSend(emoji)}
                        className="text-2xl bg-transparent border-none hover:bg-gray-800 p-2 rounded transition-transform duration-200 ease-in-out transform hover:scale-110"
                        disabled={loading} // Disable buttons while loading
                    >
                        {emoji}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default LiveComments;
