import React, {useEffect, useState} from "react";
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../config/firebaseConfig";

export interface Vote {
    type: "voting";
    game_id: string;
    round_num: number;
    voter: string;
    voted_name: string;
}

const AgentHistory = ({seasonNum, historyGames}) => {
    const [selectedGame, setSelectedGame] = useState<number | null>(null);
    const [voteData, setVoteData] = useState<Vote[]>([]);
    const [voters, setVoters] = useState<string[]>([]);
    const [winner, setWinner] = useState<string[]>([]);

    // 获取当前选中的游戏
    const currentGame = historyGames.find((game) => game.game_num === selectedGame);

    // 从 Firestore 获取投票数据
    const fetchVoteData = async () => {
        if (!currentGame) return; // 如果没有选中游戏，直接返回

        try {
            const votesRef = collection(db, `ai_ctopus/${seasonNum}/session_vote`);
            const q = query(
                votesRef,
                where("type", "==", 'voting'),
                where("game_id", "==", currentGame.game_id)
            );
            const querySnapshot = await getDocs(q);

            const votes: Vote[] = [];
            querySnapshot.forEach((doc) => votes.push(doc.data() as Vote));
            console.log('votes', votes)

            // 提取 voter 列表并保存
            const uniqueVoters = Array.from(new Set(votes.map((vote) => vote.voter)));
            setVoters(uniqueVoters);
            setWinner(currentGame.winning_agent)
            // 保存投票数据
            setVoteData(votes);
        } catch (error) {
            console.error("Error fetching vote data:", error);
        }
    };

    // 设置默认选中的游戏
    useEffect(() => {
        if (historyGames.length > 0) {
            setSelectedGame(historyGames[0].game_num); // 默认选择第一个游戏
        }
    }, [historyGames]);

    // 当 selectedGame 改变时，重新获取投票数据
    useEffect(() => {
        if (selectedGame !== null) {
            fetchVoteData();
        }
    }, [selectedGame]);

    // 按 round_num 分组投票数据
    const groupedVotes = voteData.reduce((acc, vote) => {
        if (!acc[vote.round_num]) {
            acc[vote.round_num] = [];
        }
        acc[vote.round_num].push(vote);
        return acc;
    }, {} as { [roundNum: number]: Vote[] });

    return (
        <>
            {/* 标题和下拉框 */}
            <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium">Vote Results</h3>
                <select
                    value={selectedGame || ""}
                    onChange={(e) => setSelectedGame(Number(e.target.value))}
                    className="bg-gray-700 text-gray-300 border border-gray-600 rounded px-2 py-1"
                >
                    {historyGames.map((game) => (
                        <option key={game.game_num} value={game.game_num}>
                            Game {game.game_num + 1}
                        </option>
                    ))}
                </select>
            </div>

            {/* 表格 */}
            <div className="bg-gray-800 rounded-lg overflow-hidden">
                {voteData.length > 0 ? (
                    <>
                        <table className="w-full">
                            <thead>
                            <tr className="bg-gray-700">
                                <th className="p-2 text-left">Vote Round</th>
                                {voters.map((voter) => (
                                    <th key={voter} className="p-2 text-center">
                                        {voter}
                                    </th>
                                ))}
                            </tr>
                            </thead>
                            <tbody>
                            {Object.entries(groupedVotes).map(([roundNum, votes]) => (
                                <tr key={roundNum} className="border-t border-gray-700">
                                    <td className="p-2">Vote {roundNum}</td>
                                    {voters.map((voter) => {
                                        const vote = votes.find((v) => v.voter === voter);
                                        return (
                                            <td key={voter} className="p-2 text-center">
                                                {vote ? vote.voted_name : "-"}
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {winner.length > 0 && (
                            <div>
                                <div className="p-4 text-center  text-gray-400 font-bold">
                                    <div className="flex justify-center items-center">
                                        Winner: [{winner.join(", ")}]
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="p-4 text-center text-gray-300">No votes available</div>
                )}
            </div>
        </>
    );
};

export default AgentHistory;
